import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HomePage from "./containers/HomePage/HomePage";
import HomePageExpress from "./containers/SmatchExpress/HomePageExpress/HomePageExpress";
import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import { Provider } from "react-redux";
import {
    Route,
    Redirect,
    BrowserRouter as Router,
    Switch,
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
              <Switch>
                  <Route component={HomePage} path="/" exact />
                  <Route component={HomePageExpress} path="/smatchExpress" exact />

              </Switch>
          </Router>
      {/*<HomePage />*/}
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
